@import "../../../../styles/breakpoints";

.root {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  min-height: 100%;

  width: calc(100% - 2rem);

  @include sm{
      width: min(100% - 4rem, 80rem);
  }
  padding-bottom: 6rem;

  overflow-y: visible;
  max-width: 66rem;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  h1 {
    margin: 0;
  }
}

.spinnerWrapper{
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
}