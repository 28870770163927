@import './../../styles/colors';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5rem;
  padding: 2rem;
  background-color: $logo-background-grey;
}

.logotypeContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.logotype {
  display: flex;
  height: 100%;
  &:focus {
      border-color: $gray-400;
  }
  > svg {
      width: 8rem;
  }
}