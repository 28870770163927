@import "../.../../../../../../styles/colors";
@import "../.../../../../../../styles/breakpoints";
.root{
    display: flex;
    flex-direction: column;
    --internal-margin: 2rem; 
    gap: var(--internal-margin);

    --container-padding: 2rem 1.5rem;
    max-width: 100%;

    input, textarea{
        @include lg-max{
            font-size: 1rem;
        }
    }
}

.buttonGroup{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    row-gap: 0rem;
   
}

.contactButton {
    padding:  0.125rem 0.75rem !important;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.125rem;

    > * {
        margin: 0 0.25rem;
    }
    
    &:focus, a:focus,
    &:hover, a:hover {
        color: $gray-1;
        background: $gray-13;
    }
    &:active, a:active {
        color: $clr-pressed-action;
        background: $gray-13;
    }
}

.container{
    padding: var(--container-padding) 0.5rem;
    background-color: $gray-3;
}

.label{
    font-weight: 500;
}

.pickupButtonGroup{
    display: flex;
    max-width: 13rem;
    margin-bottom: var(--internal-margin);
}

.pickupButton{
    flex-basis: 100%;
    width: unset;
    padding-left: 0;
    padding-right: 0;
}

.pickupText{
    padding-top: 0.5rem;
    color: $clr-character-secondary-45;
    margin-bottom: 0;
}

.link{
    color: $clr-title-85;
    font-weight: bold;
}

.pickupContainer{
    position: relative;
    margin-bottom: var(--internal-margin);
    & > * {
        margin: 0;
    }
}

.pickupTimePlaceholder{
    height: 4rem;
}

.pickupTimeSelect{
    position: absolute;
    inset: 2rem 0 auto 0;
}

.placeholder{
    font-weight: 400;
}

.button {
    gap: 0.25rem;

    &[disabled],
    &[disabled]:hover{
        background-color: $clr-primary;
        color: $clr-title-85;
        opacity: 0.6;
        outline: none;
    }

    & > svg{
        font-size: 1rem;
    }
}

.rangePickerDropDown{
    max-width: 100vw;

    @include sm-max{
        margin: 0 2rem;

        & :global(.ant-picker-range-wrapper){
            max-width: 100% !important;
            justify-content: center;
            align-items: center;
        }

        & :global(.ant-picker-panel-container){
            margin-left: unset !important;
        }
        

        & :global(.ant-picker-header-next-btn),
        & :global(.ant-picker-header-super-next-btn){
            visibility: visible !important;
        }

        & :global(.ant-picker-panel:last-child){
          display: none;
          width: 0;
        }
    }
}