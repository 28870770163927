@import "../../../../styles/colors";

$list-item-height: 84px;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  min-height: $list-item-height;
  border: 1px solid $gray-200;
  background-color: white;
  margin: 1rem 2rem;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  h4 {
    margin-bottom: 0;
    padding: 0 12px;
  }
  span {
    color: $gray-300;
  }
}

.actions {
  display:flex;
  align-items: center;
  > button {
    margin-left: 2rem;
    margin-bottom: 0;
  }
}

.editFaqForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  min-height: $list-item-height;
  border: 1px solid $gray-200;
  background-color: white;
  margin: 1rem 2rem;
  > div {
    margin-bottom: 0;
  }
}

.inputs {
  display: flex;
  flex-grow: 1;
  gap: 2rem;

  > div:not(div:last-child) {
    flex: 1 1 100%;
  }
}

.textArea{
  resize: none;
}

.buttonGroup{
  margin-left: 0.5rem;
  flex-shrink: 0;
}