@import "./../../../../../styles/colors";

$list-item-height: 84px;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  min-height: $list-item-height;
  border: 1px solid $gray-200;
  background-color: white;
  margin: 1rem 2rem;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  h4 {
    margin-bottom: 0;
    padding: 0 12px;
  }
  span {
    color: $gray-300;
  }
}

.actions {
  display: flex;
  align-items: center;
  > div {
    margin-bottom: 0;
  }
  a {
    padding-left: 24px;
  }
}