@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/Components.module.scss';

.modelTextNumber{
    color: $gray-7;
    margin-right: 2rem;
}

.header{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: .5rem;
}

.productTitleWrapper{
    display: flex;
}

.productTitle{
    font-size: clamp(1.25rem, 0.6rem +  2vw, 2.25rem);
    font-weight: 800;
    grid-column: 1/-1;
    margin: 0;
}

.imageContainer{
    display: flex;
    flex-direction: var(--containerDirection, column);
    gap: 2rem;
}

.addToCartContainer{
    max-width: 13.5rem;
    margin-bottom: 1.5rem;
}

.tabs{
    margin-top: 2rem;
    background-color: $gray-1;
    width: 100%;

    & :global(.ant-tabs-content){
        flex-direction: column;
        flex-grow: 1;
    }

    & :global(.ant-tabs-nav){
        margin: 1px 1px;
        outline: 1px solid var(--clr-gray-5, grey);
        display: none;

        & :global(.ant-tabs-nav-list){
            flex-wrap: wrap;
            display: none;
            @include lg{
                flex-wrap: nowrap;
            }
           
       
            & > * {
                @include md{
                    max-width: 50%;
                }
            }
         }
        & :global(.ant-tabs-tab){
            margin: 0;
            
            flex-basis: 100%;
            transition: none;
            min-width: 14rem;
            transition-duration: 0ms;
            line-height: 1.125em;
            font-size: 1.125rem;
            padding: 1rem 1.5rem;
            text-transform: uppercase;
        
            display: flex;
            align-items: center;
            justify-content: center;

            & svg {
                font-size: 1.125rem !important;
                height: 1.125rem;   
                margin-right: 1rem;
            }
        
            & span {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & :global(.anticon){
                margin: 0;
            }

            &:global(:not(.ant-tabs-tab-active)){
                color: var(--clr-title-85, black);
                        
                &:hover,
                &:focus{
                    background-color: var(--clr-gray-3, grey);
                    color: var(--clr-title-85, black);
                }
            }

            &:global(.ant-tabs-tab-active){
                background-color: var(--clr-primary);
                color: var(--clr-title-85, black);
                font-weight: 800;

                &:active,
                &:hover,
                &:focus{
                    background-color: var(--clr-gray-11, black);
                    color: var(--clr-gray-1, white);
                }

                & span{
                    color: $gray-13;
                }
    
                &:hover span{
                    color: $gray-1;
                }
            }
        }
    }
    

    & :global(.ant-tabs-ink-bar.ant-tabs-ink-bar-animated){
        display: none;
    }

    
    & :global(.ant-tabs-nav-more){
        outline: 1px solid $gray-5;
        display: block;

        @include lg {
            /* Fixes a bug where dymanic sizes of tabs causes this button to flimmer by never showing it on large screens */ 
            display: none;
        }
    }

    & :global(.ant-tabs-tabpane){
        padding: 0;

        display: flex;
        flex-direction: column;
    }
}

.spinnerWrapper{
    height: 100%;
    width: 100%;
    display: grid;
    place-content: center;
  }

.tabsList{
    list-style: none;
    width: 100%;
    padding: 0;
    border: 1px solid $gray-5;
    display: flex;
    flex-wrap: wrap;


}

.tabsListItem{
    padding: 0;
    margin: 0;
    flex-basis: 100%;

    button{
        margin: 0;
        width: 100%;
      
        transition: none;
        min-width: 14rem;
        transition-duration: 0ms;
        line-height: 1.125em;
        font-size: 1.125rem;
        padding: 1rem 1.5rem;
        height: 3rem;
        text-transform: uppercase;
        border: none;
                    
        display: flex;
        align-items: center;
        justify-content: center;

        outline: none;
    
        & svg {
            font-size: 1.125rem !important;
            height: 1.125rem;   
            margin-right: 1rem;
        }
    
        & span {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.activeButton{
    background-color: var(--clr-primary);
    color: var(--clr-title-85, black);
    font-weight: 800;
    border: none;
                    
    &:active,
    &:hover,
    &:focus{
        background-color: var(--clr-gray-11, black);
        color: var(--clr-gray-1, white);
        & *{

            color: white;
        }
    }

    & span{
        color: $gray-13;
    }

    &:hover span{
        color: $gray-1;
    }
}

.inactiveButton{
    color: var(--clr-title-85, black);
    outline: none;
    border: none;
                        
    &:hover,
    &:focus{
        background-color: var(--clr-gray-3, grey);
        color: var(--clr-title-85, black);
        & * {
            color: var(--clr-title-85, black);
        }
    }
}

.largeTabsList{
    max-width: calc(100% / 3);
}

.mediumTabsList{
    max-width: 100%;
    flex-direction: column;
}
.productInformation {
    flex-grow: 1;
}