@import './../../../../styles/colors';
@import './../../../../styles/breakpoints';

.formTitle {
    font-weight: 800;
    font-size: 2.25rem;
    text-align: center;
}

.contactFormButtonWrapper {
    text-align: center;
    margin-top: 2rem;
}

.contactFormButton {
    padding: 4px 2rem;
    height: 46px;
    display: inline-block
}

.contactForm{
    input, textarea{
        @include lg-max{
            font-size: 1rem;
        }
    }
}