@import "../../../../styles/colors";

$list-item-height: 84px;

.root {
  margin-top: 1rem;
}

.addFaqForm {
  padding: 1rem 2rem;
  min-height: $list-item-height;
  border: 1px solid $gray-200;
  background-color: white;
  margin: 1rem;
  > div {
    margin-bottom: 0;
  }
}

.inputs {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat( auto-fit, minmax(16rem, 1fr) );
  gap: 1rem;
}

.textArea{
  width: 100%;
  resize: none;
}

.buttonGroup{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 0.5rem;
}

.saveRow{
  margin-left: auto;
  display: flex;
  gap: 0.5rem;
}

.headerButtons{
  //display-flex justify-right padding-y padding-x
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
}