@import '../../../../../styles/colors';

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 0.25rem;
}
.title {
    margin: 0.5rem 0 0.5rem;
    display: block;

    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 800;
    
    /* I changed the so that all cards in the carousel is the same height. */ 
    height: 3rem;
    text-overflow:ellipsis;
    overflow:hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
    hyphens: auto;
}

.linkWrapper{
    height: 100%; 
    width: 100%;
    position: relative;
}

.link {
    display: flex; 
    flex-direction: column;
    height: 100%; 
    width: 100%;
    & > a {
        margin: 0;
    }
}

.image {
    margin: 0;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    background-color: $gray-1;
    border: 1px solid $gray-5;
    overflow: hidden;
}

.noImage {
    background-color: $gray-100;
}

.moreInfoButton{
    line-height: 1.125em;
    font-size: 0.875rem;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    height: auto;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    text-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    flex-grow: 1;

    color: var(--clr-gray-1, white);
    background-color: var(--clr-gray-9, black);

    &:focus{
        color: var(--clr-gray-1, white);
        background-color: var(--clr-gray-9, black);
        outline: 2px solid var(--clr-gray-9, black);
    }
    
    &:active,
    &:hover{
        background-color: var(--clr-gray-11, black);
        color: var(--clr-gray-1, white);
        outline: 2px solid $gray-11;
    }
}

.buttonContainer {
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 0.5rem;
}