@import '../../../../../styles/colors';

.root {
    display: flex; 
    flex-direction: column;
    --rowHeight: 0.875;

}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem; 

    background-color: $gray-3;

    & > *:first-child{
        flex-grow: 1;
    }
}

.title {
    margin-bottom: 0;
    font-size: var(--rowHeight);
    font-weight: 500;
    overflow: hidden;
}

.list {
    padding: 0;
    margin: 0;
}

.button{
    height: var(--rowHeight);
    padding: 0.25rem;
}