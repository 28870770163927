@import '../../../styles/colors';


.sticky {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 24px;
  border-top: 1px solid $gray-100;
  background-color: $background;
}

.root {
  margin-top: 1rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}