@import "../../styles/colors";

$list-item-height: 84px;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  min-height: $list-item-height;
  border: 1px solid $gray-200;
  background-color: $background;
  margin-bottom: 1rem;
}

.dragAndDropIcon {
  font-size: 1rem;
}

.link {
  flex-grow: 1;
  text-decoration: underline;
  margin-left: 2rem;
}

.image {
  width: 4rem;
  margin-left: 2rem;
}