// Hyrmax / ant primary
$clr-primary: #FDC612;
$clr-text-primary: #FFFFFF;
$clr-pressed-action: #888888;
$clr-title-85: rgba(0, 0, 0, 0.85); 
$clr-character-secondary-45: rgba(0, 0, 0, 0.45);
$clr-transparant-black: rgba(0, 0, 0, 0.05);
$clr-transparant-white: rgba(255, 255, 255, 0.05);
$clr-disabled-25: rgba(0, 0, 0, 0.25);
$clr-disabed-white-25: rgba(255, 255, 255, 0.25);
$clr-disabed-white-33: rgba(255, 255, 255, 0.33);
$clr-disabed-white-45: rgba(255, 255, 255, 0.45);
$clr-disabed-white-50: rgba(255, 255, 255, 0.5);
$clr-disabed-white-75: rgba(255, 255, 255, 0.75);

// Ant colours 

// Neutral Color Palette
$gray-1: #ffffff;
$gray-2: #fafafa;
$gray-3: #f5f5f5;
$gray-4: #f0f0f0;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #595959;
$gray-9: #434343;
$gray-10: #262626;
$gray-11: #1f1f1f;
$gray-12: #141414;
$gray-13: #000000;

// Custom Colours 

$foreground: black;
$background: white;

// Grayscale
$gray-50: #fafafa;
$gray-100: #f1f1f1;
$gray-200: #e0e0e0;
$gray-300: #939393;
$gray-400: #616161;
$gray-500: #474747;
$gray-600: #2b2b2b;

// Error
$error: #B92020;

$logo-background-grey: $gray-11;

$navbar-background-color: $gray-9;
