@import '../../../../styles/breakpoints';

.root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.root > *:last-child{
    justify-self: flex-end;
}

.hideScrollBar{
    height: var(--documentHeight, 100vh);
    overflow-y: hidden;
}

.main {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    position: relative;
}

.pageContainer {
    min-height: 100%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.hidePageContainer {
    --height-of-navbar: 5rem;    
    height: calc(var(--documentHeight, 100vh) - var(--height-of-navbar));
    overflow-y: hidden;


    @include sm{
        --height-of-navbar: 5.1875rem;
    }
}

.hide{
    display: none
}

.show{
    width: 100%;
}