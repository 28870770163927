@import "../../../../../styles/colors";

@import "../../../../../styles/breakpoints";

.root{
    background-color: $gray-3;
    padding: 1.5rem 0rem;

    margin-left: -1rem;
    margin-right: -1rem;

    @include sm{
        margin: 0;
        padding: 1.5rem 1rem;
    }
}

.title{
    text-transform: uppercase;
    margin: 0 2.375rem 1.5rem;
    // @include sm{
    //     // 3.375rem is the size of the buttons for the carousel + the 1rem gap to the first card
    //     margin: 0 2.375rem 1.5rem;
    // }
    padding: 0;
    // bold 18
    font-size: 1.125rem;
    font-weight: 700;
}