@import "../../../../../styles/colors";
@import "../../../../../styles/breakpoints";

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        margin: 0;
    }
}

.landingPageCarousel {
    width: 100%;
    //max-width: 1000px;
    position: relative;


    & *:global(.slick-list){
        overflow-x: hidden;
        overflow-y: visible;

        padding-bottom: 6rem;
        @include sm {
            padding-bottom: 0;
        }
    }

    & *:global(.slick-list){
        padding: 0;
    }

    & *:global(.slick-slide){
        aspect-ratio: 1280/512;   
    }
    

    & .button {
        z-index: 10;
        position: absolute;
        top: 100%;
        width: calc(100vw - 2rem);
        @include sm {
            width: unset;
            top: unset;
            bottom: max(15%, 24px);
            transform: translateX(67px);
        }
    }
    div {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;

        background-size: contain;
        // @include md {
        //     background-size: 100% auto;
        // }
    }


    & :global(.slick-dots) {
        bottom: 0;
        & > li {
            border-radius: 5px;
            border: 2px solid transparent;
            @include sm {
                border: 2px solid hsla(0, 0%, 0%, 0.015);
            }
            &:global(.slick-active) > button{
                background-color: $clr-primary;
            }
        }
    }
}

.carouselImage{
    cursor: pointer;
}