@import '../../../../styles/colors';

.heartButton {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.54);
    z-index: 999;

    --shadow: rgba(255, 255, 255, 0.20); 

    filter: drop-shadow(1px 1px 0px var(--shadow)) 
            drop-shadow(0px 1px 0px var(--shadow))  
            drop-shadow(1px 0px 0px var(--shadow)) 
            drop-shadow(1px -1px 0px var(--shadow)) 
            drop-shadow(-1px 1px 0px var(--shadow))
            drop-shadow(0px -1px 0px var(--shadow)) 
            drop-shadow(-1px 0px 0px var(--shadow))
            drop-shadow(-1px -1px 0px var(--shadow));
}