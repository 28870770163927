.uploadList {
    margin-top: 1rem;
}

.uploadListItem {
    justify-self: flex-start;
}

.listIcon {
    font-size: 20px;
}