.root{
    display: grid;
    place-items: center;
    min-height: 100%;
}

.info {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.layout{
    margin-left: auto;
    margin-right: auto;

    //70ch represents the maximum amount of letters that humans are comfortable reading in row. 
    max-width: 70ch;

}