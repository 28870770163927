@import "../../../../../../styles/breakpoints";
@import "../../../../../../styles/colors";

.actionsContainer{
    width: 100%;
    display: flex;
    
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
}

.inputWrapper{
    display: flex;

    & > .input {
        width: 2.375rem;
        height: 2.375rem;
        border-radius: 0;
        display: grid;
        place-items: center;

        border-left: 0px;
        border-right: 0px;
        text-align: center;
        
        // Remove the buttons that comes with InputNumber
        & > div:first-child {
            display: none;
        }

        & input{
            padding: 0;
            text-align: center;
        }

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        -moz-appearance: textfield;
    }

    & > button {
        width: 2.375rem;
        height: 2.375rem;
        border-radius: 0;
    }

}

.addButton{
    flex-grow: 1;
    padding-left: 0;
    padding-right: 0;

    &Padding{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    max-width: 5rem;
}

.inCard{
    & .inputWrapper{
        display: none;
    
        @include sm{
            display: flex;
        }
    }

    & .addButton{
        max-width: none;
    
        @include sm{
            max-width: 5rem;
        }
    }
}