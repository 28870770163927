.root {
  height: 100vh;
  width: 100vw;
  margin: 0;
  overflow: hidden;
}

.spinner {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.downloadContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}