@import './../../../../styles/colors';
@import './../../../../styles/breakpoints';

.root {
    background-color: $gray-9;
    color: $clr-text-primary;
    padding: 4rem clamp(1%, 10rem, 10%);
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    row-gap: 2rem;
    flex-wrap: wrap-reverse;
    @include md-max {
        flex-direction: column-reverse;
        gap: 3rem;
    }
}

.linksContainer {
    display: flex;
    gap:  6rem;
    
    @include md-max {
        flex-direction: column;
        gap: 3rem;
    }
}

.logotypeContainer {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: bolder;
    gap: 1rem;
    flex-wrap: wrap;
    @include md-max {
        justify-content: center;
    }
    
}

.logosContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include md-max {
        align-items: center;
        margin: 0;
        gap: 1rem;
    }

    & > p {
        margin-bottom: 0;
    }
}
.slogan {
    width: 10rem;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(250deg) brightness(107%) contrast(102%);
}

.logotype {
    width: 13rem;
}

.linksContainer {
    display: flex;
    flex-wrap: wrap;
}

.linkList {
    @include md-max {
        text-align: center;
    }
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: right;


    & h2 {
        color: $clr-text-primary;
        text-transform: uppercase;
        font-size: medium;
    }
}

.navList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
   
    & a {
        font-weight: lighter;
        color: $clr-text-primary;
        &:focus{
            color: $clr-primary;
        }
        &:hover{
            color: $clr-primary;
        }
        &:active{
            color: $clr-pressed-action;
        }
    }
}

.iconLinksContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include md-max {
        align-items: center;
    }
}

.deliveryPartners{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.partnerWrapper{
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    place-items: center;
    max-width: 20rem;
    margin-right: auto;
    margin-left: auto;
}

.partner{
    max-width: 150px;
    margin: 0;
}

.partnerSmall{
    max-width: 100px;   
    max-height: 50px;
    margin: 0;
}