@import './../../styles/breakpoints';
.root {
  width: 100%;
  form {
    @include md {
      display: block;
      width: 100%;
    }
    @include lg {
      display: flex;
      width: calc(100% - 12px);
      flex-wrap: nowrap;
      > div {
        width: 100%;
      }
      > * {
        &:first-child {
          padding-left: 0;
        }
        padding: 0 12px;
      }
    }
    @include xl {
      width: 90%;
      max-width: 1200px;
    }
  }
}
