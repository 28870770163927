@import '../../styles/breakpoints';

.tree{
    --clr-border: rgba(0, 0, 0, 0.03); 

    & :global(.ant-tree-treenode){
        flex-direction: row-reverse;
        position: relative;

        padding: 0.5em 3em;
        @include lg{
            padding: 0.5em 1.5em;
        }
    }

    & :global(.ant-tree-indent){
        --border-thickness: 1px; 

        &::before{
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            border-bottom: var(--border-thickness) solid var(--clr-border);
            left: 0;
            bottom: 0;
        }

        /* 
            Because of the flat structure of the HTML representation of the tree, 
            these .ant-tree-indent-units are there only way to represent the depth
            of it. It starts at 0, and one is added for layer deeper we go in the 
            tree. 
        */ 
        & > :global(.ant-tree-indent-unit) {
            background-color: var(--clr-transparant-black);
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%; 
            position: absolute;
        }
    }

    & :global(.ant-tree-treenode-switcher-open) {
        background-color: var( --clr-transparant-black);
    }
    & :global(.ant-tree-switcher_open){
        transform: rotate(180deg);
    }

    & :global(.ant-tree-switcher_close){
        transform: rotate(90deg);
    }

    &:global(.ant-tree .ant-tree-node-content-wrapper){
        &:hover{
            background-color: transparent;
            & :global(.ant-tree-title){
                font-weight: 500;
            }
        }
    }

    /* Node in that responds to the current page */
    &:global(.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected){
        background-color: transparent;
        font-weight: 700;
    }

    & span:global(.ant-tree-title){
        text-transform: uppercase;
    }

    & :global(.ant-tree-treenode-selected){
        background-color: hsla(0, 0%, 0%, 0.1);
    }
}

