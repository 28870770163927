@import './../../../../styles/colors';
@import '../../../../styles/breakpoints';
.container{
    max-width: 1200px;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;

    @include sm{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @include md{
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

.contactTitle {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.title{
    font-size: 2.25;
    font-weight: 400;
    text-transform: uppercase;
}

.info {
    margin-bottom: 4rem;
}

.address {
    display: flex;
    flex-direction: column; 
    margin-bottom: 0; 
}

.layout {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    --gap: 3.5rem;
    gap: var(--gap);

    background: $gray-3;
    padding: 2.5rem 2.5rem;

}

.column{
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.mapEmbed {
    min-height: 32.5rem;
    > iframe {
        border: 0;
        height: 100%;
        width: 100%;
    }
}

.link {
    color:black;
    text-decoration: underline;
}

.listTitle {
    text-transform: uppercase;
    margin-bottom: 0;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.iconButtonGroup{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    
}

.iconButton{
    font-weight: 500;
    & > *:first-child{
        margin-left: 0;
    }
}

.formWrapper{
    margin-bottom: 6rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    width: min(50vw + 10rem, 100%);
}