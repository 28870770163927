$screen-sm-min: 36rem; // = 576px;

// Small tablets (portrait view)
$screen-md-min: 48rem; // = 768px;

// Tablets and small desktops
$screen-lg-min: 62rem; // = 992px;

// Large tablets and desktops
$screen-xl-min: 100rem; // = 1600px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin sm-max {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Medium devices - maximum content witdh
@mixin md-max {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
// Large devices - maximum content witdh
@mixin lg-max {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl-max {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}

