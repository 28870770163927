@import '../../../../styles/colors';
@import '../../../../styles/breakpoints';

.root {
    --spacer: 1rem; 
    background-color: $navbar-background-color;
    padding: 1.5rem 1rem;
    display: grid;
    grid-template-columns: min-content auto min-content;
    grid-template-areas: "hamburgerWithLogo info iconGroup";

    @include md{
        padding: 1.5rem 2rem;
        column-gap: var(--spacer);
    }

    position: sticky;
    top: 0;
    z-index: 1001;

    .hamburgerWithLogo { 
        grid-area: hamburgerWithLogo info;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        @include sm{
            grid-area: hamburgerWithLogo;
        }
    }

    .info { 
        grid-area: info; 
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: var(--spacer);
        --margin: min(3vw, 4rem);

        & > div:first-child{
            padding-top: var(--spacer);
            width: 100%;
            @include sm{
                width: auto;
            }
            
            @include lg{
                padding-top: 0;
                margin-left: var(--margin);
            }
        }
    }

    .iconGroup { 
        grid-area: iconGroup; 
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: var(--spacer);
    }
      
    
    @include lg-max {
        display: grid; 
        grid-template-columns: auto auto auto; 
        grid-template-rows: auto auto; 
        
        grid-template-areas: 
            "hamburgerWithLogo hamburgerWithLogo iconGroup"
            "info info info"; 

        .hamburgerWithLogo { 
            @media (max-width: 500px) {
                .slogan {
                    display: none;
                }
                .logotype {
                    margin: 0;
                }
            }
        }

        .iconGroup { 
            grid-area: iconGroup;
        }

        .info {
            grid-area: info;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
}

.logotypeContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.menuItem {
    text-transform: uppercase;
    color: $clr-text-primary;
    &:focus {
        color: $clr-primary;
    }
    &:hover {
        color: $clr-primary;
    }
    &:active {
        color: $clr-pressed-action;
    }
}

.linkWrapContainer {
    display: flex;
    align-items: center;
    gap: var(--spacer);
}

.slogan {
    display: none;
    @include sm{
        display: unset;
    }
    width: 8rem;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(250deg) brightness(107%) contrast(102%);
}

.logotype {
    max-width: 100%;
    width: 11rem;

    @include sm{
        max-width: none;
    }
}

.favouriteButton {
    > span { 
        font-size: 20px;
    }
}

.cartPageIsOpen{
    color: $clr-disabed-white-50;

    & > span{
        color: $clr-disabed-white-50;
    }
}

.cartButton{
    & > span{
        font-weight: 800;
        display: none;
        @include sm{
            display: unset;
        }
    }
}

.buttonIcon{
    display: grid;
    place-items: center;
    grid-auto-flow: column;
    gap: .5rem;
    & * {
        padding: 0;
        margin: 0;
    }
}
