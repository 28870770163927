@import '../../../../../styles/colors';

.root {
    margin-top: 2rem;
}

.list{
    width: 100%;
    border:none;

    
    background-color: $gray-1;
    
    & li:global(.ant-list-item):nth-child(odd){
        background-color: $gray-3;
    }

    & li:global(.ant-list-item){
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    & ul ul{
        padding: 0;
        margin: 0;

        display: flex;
        justify-content: flex-end;

        li {
            padding: 0;
            margin: 0;
        }
    }

    & li:global(.ant-list-item){ 
        display: grid;
        grid-template-columns: auto max-content;
        gap: 0.5rem;
    }

    & div.showMore{
        margin: 0.5rem;
        background-color: inherit;
        text-align: center;
        height: 2rem;
        display: flex;
        justify-content: center;
    }
}

.inputWrapper{
    display: flex;

    & > .input {
        width: 2.375rem;
        height: 2.375rem;
        border-radius: 0;
        display: grid;
        place-items: center;

        border-left: 0px;
        border-right: 0px;
        text-align: center;
        
        // Remove the buttons that comes with InputNumber
        & > div:first-child {
            display: none;
        }

        & input{
            padding: 0;
            text-align: center;
        }

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        -moz-appearance: textfield;
    }

    & > button {
        width: 2.375rem;
        height: 2.375rem;
        border-radius: 0;
    }

}

.listContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.link{
    font-weight: 800;
    color: $gray-13;

    &:hover,
    &:focus{
        color: $clr-primary;
    }
}

.showMoreLink {
    cursor: pointer;
    text-align: center;
    background-color: $clr-primary;
    padding: 0.5rem 1rem;
    font-weight: 700;
    line-height: 1.25rem;
}

.addToCartButton {
    padding: 0.5rem 1rem;
    font-weight: 700;
}

.addButton{
    flex-grow: 1;
    padding-left: 0;
    padding-right: 0;

    &Padding{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

h2.title {
    line-height: 1.125em;
    font-size: 1.125rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 1rem;
}
