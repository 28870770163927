@import '../../../../../styles/colors';
@import '../../../../../styles/breakpoints';
.root{
    display: grid;
    gap: 1rem; 

    width: fit-content;

    @include md {
        grid-template-columns: var(--image-size) max-content;
        --image-size: 27.5rem;
    }
}

.imageWrapper{
  
    max-width: var(--image-size);
    aspect-ratio: 1;
    background-color: $gray-1;
    border: 1px solid $gray-5;

    display: grid;
    place-items: center;
}

.preview {
    max-width: 100%;
    object-fit: contain;
    aspect-ratio: 1/1;
    max-height: 100%;
}

.thumbnailGroup{
    min-width: 6rem;
    min-height: 6rem;
    max-height: var(--image-size);
    max-width: var(--image-size);
    
    
    display: flex;
    overflow-x: auto;
    gap: 0.5rem;
    padding-bottom: 0.5rem;

    @include md {
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 0rem;
        padding-right: 0.5rem;
    }
}

.button {
    border: 0;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    width: 100%;
    &:hover {
        opacity: 0.9;
    }
}

.thumbnail {
    // max-width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    height: 5rem;
    width: 5rem;
    background-color: $gray-1;
    border: 1px solid $gray-5;
}

.thumbnailActive{
    outline: 2px solid $gray-11;
    outline-offset: -2px;
}