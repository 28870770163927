@import "../../../../styles/colors";

.root{
    height: 100%;
    width: 100%;
    display: grid;
    justify-items: center;
    align-content: center;
    gap: 1rem;
}

.icon{
    font-size: 5rem !important;
    color: $gray-9;
}

.text{
    text-align: center;
}

.inverted{
    color: $clr-text-primary;
}