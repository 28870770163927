@import './../../styles/colors';

.root {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  border-top: 1px solid $gray-100;
  background-color: white;
  > div:first-child {
    span {
      padding-left: 6px;
    }
  }
  .button-group {
    > div {
      margin-left: 6px;
    }
  }
}
