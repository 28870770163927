@import '../../../../styles/breakpoints';

.container {
    display: grid;
    padding: 0;
    margin-top: 2rem;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
    column-gap: 1.5rem;
    justify-content: flex-start;
    grid-template-columns: repeat( auto-fill, minmax(7rem, 1fr));
    @include sm{
        grid-template-columns: repeat( auto-fill, minmax(10rem, 1fr));
    }
    @include lg{
        grid-template-columns: repeat( auto-fill, minmax(14.5rem, 1fr));
    }
}

.item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
