.root {
  margin-top: 1rem;
}

.addCategoryForm {
    display: flex;
    > div {
      &:first-child {
        width: calc(100% - 150px);
        padding-right: 12px;
      }
      &:last-child {
        > div {
          justify-content: flex-end;
          padding-right: 0;
        }
      }
    }
  }