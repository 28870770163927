@import '../../../../styles/colors';
@import '../../../../styles/_breakpoints';

.root{
    flex-grow: 1;
    flex-shrink: 0;
    background-color: $gray-1;
    display: flex; 
    flex-direction: column;
    align-items: flex-start;

    position: absolute; 
    width: 100%;
    z-index: 1000;
    height: fit-content;

    @include lg{
        position:static; 
        width: 22rem;
        height: unset;
    }
}

.fixed{
    background-color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;

    width: 100%;
    z-index: -1;
    
    position: sticky;
    --height-of-navbar: 5rem;
    --documentHeight: 100vh;
    top: var(--height-of-navbar);    
    
    height: calc(var(--documentHeight, 100vh) - var(--height-of-navbar));
    padding: 0rem 0rem;
    border-left: solid 1px $gray-5;

    @include sm{
        --height-of-navbar: 5.1875rem;
    }
    
    @include lg{
        overflow-y: overlay;
        &:not(:hover){
          overflow-y: hidden;
        }
    }
}

.checkoutButton{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding: 0.42rem;

    padding: 0.5rem 1.3125rem 0.5rem 1.5rem;
    min-height: 2.6125rem;

    & > span, &:hover > span, &:focus > span{
        position: absolute;
        inset: 0;
        display: grid;
        place-items: center;
        font-size: 0.875rem;
        text-align: center;
        padding: 0.85rem 0;
    }

    & > svg{
        justify-self: flex-start;
    }

   @include lg{
        padding-right: 1rem;
   } 
}