@import '../../../../../../styles/colors';
@import '../../../../../../styles/breakpoints';

.root {
    --rowHeight: 1.5rem;
    --imageSize: 3.875rem;
    min-height: calc(var(--imageSize) + 1.2em) ;
    
    border-bottom: 1px solid $gray-200;
    padding: 0.5rem;
    list-style-type: none;
    display: grid;
    grid-template-columns: var(--imageSize) 1fr auto ;
    grid-template-rows: 1fr auto;
    align-items: center;
    background: $gray-1;
    gap: 0.25rem;

}

.image {
    grid-row: 1 / 3;
    max-width: var(--imageSize);
    max-height: var(--imageSize);
    border: 1px solid $gray-5;
}

.productInfo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-y: hidden;
    text-overflow: ellipsis;

    grid-column: 2/3;
    height: calc(0.875rem * 2);
}

.productInfoNoImage{
    grid-column: 1/3;
}

.productName {
    cursor: pointer;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: $gray-13;

    &:hover,
    &:focus{
        color: $clr-primary;
    }
}

.productTitle {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: $gray-13;
}


.action {
    align-self: flex-start;
    height: var(--rowHeight);
    width: max-content;
    padding: 0.125rem 0.5rem;
    font-size: 0.75rem;
    line-height: 0.75rem;

    grid-column: 2/3;
    grid-row: 2/-1;
}

.actionNoImage{
    grid-column: 1/3;
    grid-row: 2/-1;
    padding: 0.125rem 1rem;
}

.inputWrapper{
    display: flex;

    & > .input {
        width: var(--rowHeight);
        height: var(--rowHeight);
        border-radius: 0;
        display: grid;
        place-items: center;

        border-left: 0px;
        border-right: 0px;
        text-align: center;
        
        // Remove the buttons that comes with InputNumber
        & > div:first-child {
            display: none;
        }

            padding: 0;
            text-align: center;
            font-size: 0.75rem;
            line-height: 0.75rem;
            height: 100%;
    

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        -moz-appearance: textfield;
    }

    & > button {
        width: var(--rowHeight);
        height: var(--rowHeight);
        border-radius: 0;
        padding: 0.25rem
    }
}

.buttonGroup {
    display: flex;
    justify-self: end;
    gap: 0.25rem;
    grid-column: -1;
    grid-row: 2;

    & > button{
        width: var(--rowHeight);
        height: var(--rowHeight);
        padding: 0.25rem
    }
}

.button{
    font-weight: 400;
    text-transform: capitalize;
}

.rootIsAside{
    @include lg{
        padding-left: 1.3em;
    }
}

.buttonGroupAside{
    @include lg{
        padding-right: .7em;
    }
}