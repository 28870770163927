@import './../../../../styles/colors';
@import './../../../../styles/breakpoints';

.root {
    & * {
        margin: 0 0.5rem;
    }
    
    display: flex;
    align-items: center;
    padding: 0 !important;
    font-weight: lighter;
    position: relative;

    &:focus,
    &:hover,
    &:active{
        background-color: transparent;
    }

    &:hover .counter,
    &:focus .counter{
        color: $clr-primary;
    }
}

.white{
    color: $clr-text-primary;
    
    &:focus{
        color: $clr-primary;
    }
    &:hover{
        color: $clr-primary;
    }
    &:active{
        color: $clr-pressed-action;
    }
}

.primary{
    color: $clr-primary;
    
    &:focus{
        color: $clr-text-primary;
    }
    &:hover{
        color: $clr-text-primary;
    }
    &:active{
        color: $clr-pressed-action;
    }
}

.black{
    color: $gray-13;
    
    &:focus{
        color: $clr-primary;
    }
    &:hover{
        color: $clr-primary;
    }
    &:active{
        color: $clr-pressed-action;
    }
}

.counter{
    position: absolute;
    color: $gray-1;
    background-color: hsla(0, 0%, 0%, 0.8);
    font-size: 0.6rem;
    right: -0.5rem;
    top: -0.33rem;
    padding: 0.125rem !important;
    border-radius: 9999rem;
    font-weight: 400;

    min-width: 1rem;

    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}