@import './../../styles/colors';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid $gray-100;
  .searchInput {
    width: 50%;
    max-width: 400px;
  }
  h2 {
    margin: 0;
  }
}
