.editProductForm {
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  > * {
    width: calc(50% - 12px);
  }

}

.productImages {
  display: flex;
  flex-direction: column;
}

.mainToggleComponent {
  display: inline-flex;
}

.mainToggleSwitch {
  padding-left: 1rem;
}

.selectDropDown {
  display: none !important;
}

.siteTagPlus {
  background: #fff;
  border-style: dashed;
  padding: 0.6em 0.5rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
}
.siteTagRemove {
  padding: 0.6em 0.5rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
}
.editTag {
  user-select: none;
  padding: 0.6em 0.8rem;
  margin-bottom: 0.6rem;
}
.tagInput {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}

[data-theme="dark"] .site-tag-plus {
  background: transparent;
  border-style: dashed;
}

.imageGridContainer {
  border: 1px solid #cfcfcf;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex: 1;
}