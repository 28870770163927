@import '../../../../../styles/colors';

.root{
    & tr:global(.ant-table-row):nth-child(odd){
        background-color: $gray-3;
    }
}

.attributeName {
    text-transform: uppercase;
}