@import "../../../../styles/colors";
@import "../../../../styles/breakpoints";

.resourceList {
  padding: 1rem;
  background-color: $gray-3;
}

.boxContainer {
  display: flex;
  gap: 2rem;
  max-width: 100%;
  @include xl-max {
    flex-wrap: wrap;
  }
}

.resourceList {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.button {
  padding:  0.125rem 0.75rem !important;
  height: fit-content;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.125rem;

  max-width: calc(100vw - 7rem) ;
  > * {
    margin: 0 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &:focus, a:focus,
  &:hover, a:hover {
      color: $gray-1;
      background: $gray-13;
  }
  &:active, a:active {
      color: $clr-pressed-action;
      background: $gray-13;
  }
}

.spinnerWrapper{
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
}