@import '../../../../styles/colors';
@import '../../../../styles/breakpoints';

.root {
    --clr-bg: #000000;
    background-color: var(--clr-bg);
    // This data url is 1x1 px wide transparent square and is used as a simple placeholder
    --bg-small: url("data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
    --bg-medium: url("data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
    --bg-large: url("data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");

    //This adds the faded-effect to the hero's background
    position: relative;
    &::after{
        content: "";
        position: absolute;
        inset: 0;
        background-color: hsla(0, 0%, 100%, 0.08);
        pointer-events: none;
    }
}


.container{
    max-width: var(--contain-width,1800px);
    min-height: 40vh;
    position: relative;
    display: flex;
    flex-direction: column;
    color: $gray-1;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    margin-right: auto;
    margin-left: auto;

    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2)  100%), var(--bg-small);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: auto 100%;

    padding: 1rem 2rem;

    @include md{
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8)  50%, rgba(0, 0, 0, 0.2) 70%, transparent 100%), var(--bg-medium);
        &.right {
            text-align: right;
        }
        
        &.left {
            text-align: left;
        }
    }

    @include lg{
        padding: 0;
    }

    @include xl{
        background-image: var(--bg-small);
    }

    @include xl{
    
        justify-content: center;
    }

}

.textContent{
    padding-left: min(10rem, 3vw);
    padding-right: min(10rem, 3vw);
    width: 100%;
    z-index: 1;
}

.title{
    position: relative;
    color: $gray-1;
    
    font-weight: 800;
    font-size: clamp(2rem, 1rem + 5vw, 3rem);
    line-height: 1.2;

    margin-bottom: 0.25em;
    @include md{
        line-height: 1.1;
        max-width:  60%;
    }
}

.subtitle{
    color: $clr-primary;
    font-size: clamp(1.25rem, 5vw, 1.5rem);
    line-height: 1.4;
    margin-top: 0.75em;
    @include md{
        line-height: 1.1;
        max-width:  60%;
    }
}