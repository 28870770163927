@import "../../../../../styles/colors";
@import '../../../../../styles/breakpoints';

.root{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 45ch;
    padding: 2rem 1.5rem; 
    
    @include sm{
        padding: 1rem 4rem 2rem ; 
    }    

    @include xl{
        padding: 1.5rem 4rem 3rem;
    }
}

.cardRegular, 
.cardRegular > h2{
    background-color: $gray-9;
    color: $clr-text-primary;
}

.cardInverted,
.cardInverted > h2{
    background-color: $clr-primary; 
    color: $clr-title-85;
    ::selection {
        background: rgba(0, 0, 0, 0.2)
      }
}

.header{
    font-weight: 800;
    font-size: clamp(1.75rem, 1rem + 2vw, 3rem);
    margin-bottom: 1rem;
    text-align: center;
}

.text{
    font-size: clamp(1rem, 0.5rem + 0.75vw, 1.5rem);
    line-height: 1.33333em;
    max-width: 45ch;

    text-align: center;

    @include sm{
        min-width: 30ch;
    }
}

.button{
    margin-top: auto;
    font-size: 1.125rem;
    padding: 1rem 1.5rem;
    white-space: normal;
}