.tableHeader {
    margin-top: 2rem;
    padding: 0rem 2rem;
}

.divider {
    margin: 0;
}

.spinner {
    display: flex;
    padding-top: 4rem;
    justify-content: center;
}