@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';

.container {
    --nbr-of-cards: 100; 
    --with-hidden-cards: 102;
    --gap: 0.5rem;
    --margin-inline-end: calc(var(--gap) * 1);
    --animation-timer: 350ms;

    --button-size: 2.375rem;

    position: relative;
    margin-top: 0rem;
    max-width: 100%;

    padding: 0  calc(var(--button-size) - var(--gap)) 0 var(--button-size);
    background-color: inherit;
    
    @include sm-max{
        overflow-x: hidden;
    }

    @include md{
        padding:0  calc(var(--button-size) - var(--gap)) 0 var(--button-size);
    }
}

.row{
    overflow: hidden;
    margin-right: calc(0 -  var(--gap));
    background-color: inherit;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right:0;
        width: calc(var(--gap) - 1px);
        background-color: inherit;
    }
}

.list{
    margin: 0;
    display: grid;
    padding: 1px;
    grid-template-columns: repeat(min(var(--with-hidden-cards),4), 1fr);
    width: calc(min(4, var(--with-hidden-cards)) / min(2, var(--nbr-of-cards)) * 100%); 

    & > * {
        /* Because gap can vary slightly to make room for items in the grid
           and we need it to be exact in this instance as we are hiding things. 
           off screen. Instead we have to make do with a margin that is static. */ 
        margin-right: var(--gap);
    }
    
    @media (min-width: 450px){
        grid-template-columns: repeat(min(var(--with-hidden-cards),5), 1fr);
        width: calc(min(5, var(--with-hidden-cards)) / min(3, var(--nbr-of-cards)) * 100%); 
    }
    
    @media (min-width: 840px){
        grid-template-columns: repeat(min(var(--with-hidden-cards),6), 1fr);
        width: calc(min(6 , var(--with-hidden-cards)) / min(4, var(--nbr-of-cards)) * 100%); 
    }

    @media (min-width: 1060px){
        grid-template-columns: repeat(min(var(--with-hidden-cards),7), 1fr);
        width: calc(min(7 , var(--with-hidden-cards)) / min(5, var(--nbr-of-cards)) * 100%); 
    }

    @include xl {
        grid-template-columns: repeat(var(--with-hidden-cards), 1fr);
        width: calc(var(--with-hidden-cards) / var(--nbr-of-cards) * 100%); 
    }
}

.item {
    transform: translateX(calc(-100% - var(--gap)));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    
     & button {
        padding-inline: 0.5rem;
        flex-grow: 1;
        width: auto;
     }
}

.itemForward{
    transform: translateX(calc((-100% - var(--gap))*2));
    transition: transform var(--animation-timer) ease;
}

.itemBackward{
    transform: translateX(0);
    transition: transform var(--animation-timer) ease;
}

.itemSpecialAnimation{
    transform: translateX(calc(-100% - var(--gap)));
    transition: none;
}


.button{
    top: 50%;
    height: 100%;
   
    margin: 0;
    padding: 1.25rem;
    width: var(--button-size);
    position: absolute;
    transform: translateY(-50%);
    padding: 0;

    @include sm{
        height: var(--button-size);
    }
}

.buttonBackwards{
    left: 0;
    @include sm{
        left: -.5rem;
    }
}

.buttonForwards{
    right: 0;
    @include sm{
        right: -0.5rem;
    }
}

.emptyContainer{
    display: flex;
    min-height: 16rem;
    align-items: center;
    justify-content: center;
}

.emptyText{
    text-align: center;
    color: $clr-disabled-25;
}