@import './styles/breakpoints';
@import './styles/colors';
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* rubik-300 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/rubik-v19-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/rubik-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/rubik-v19-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-600 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/rubik-v19-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-600.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-700 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/rubik-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-700.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-800 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/rubik-v19-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-800.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-800.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-900 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/rubik-v19-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-900.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-900.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-300italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  src: url('./assets/fonts/rubik-v19-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-300italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/rubik-v19-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/rubik-v19-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-500italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-600italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/rubik-v19-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-600italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-700italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/rubik-v19-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-700italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-800italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  src: url('./assets/fonts/rubik-v19-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-800italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-800italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-900italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  src: url('./assets/fonts/rubik-v19-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/rubik-v19-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/rubik-v19-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/rubik-v19-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/rubik-v19-latin-900italic.svg#Rubik') format('svg'); /* Legacy iOS */
}

:root{
  --clr-primary: #fdc612;
  --clr-title-85: rgba(0, 0, 0, 0.85); 
  --clr-transparant-black: rgba(0, 0, 0, 0.05);
  --clr-transparant-white: rgba(255, 255, 255, 0.05);

  --clr-gray-1: #ffffff;
  --clr-gray-3: #f5f5f5;
  --clr-gray-4: #f0f0f0;
  --clr-gray-5: #d9d9d9;
  --clr-gray-6: #bfbfbf;
  --clr-gray-7: #8c8c8c;
  --clr-gray-8: #595959;
  --clr-gray-9: #434343;
  --clr-gray-10: #262626;
  --clr-gray-11: #1f1f1f;
  --clr-gray-12: #141414;
  --clr-gray-13: #000000;
}

body{
  font-family: "Rubik", Arial, Helvetica, sans-serif;
}

.content {
  display: flex;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.logo {
  width: 200px;
  text-align: center;
}

.left-side-menu {
  min-height: calc(100vh - 80px);
  border-right: 1px solid $gray-100;
}

.page {
  width: calc(100vw - 80px);
  display: flex;
  flex-direction: column;
  position: relative;
}

.ant-tabs-content-holder {
  margin-bottom: 24px;
}

.padding {
  padding: 24px;
}

.padding-top {
  padding-top: 24px;
}

.margin-top {
  margin-top: 24px;
}

.padding-y {
  padding-top: 24px;
  padding-bottom: 24px;
}

.padding-bottom-small {
  padding-bottom: 6px;
}

.margin-right {
  margin-right: 24px;
}

.padding-x {
  padding-left: 24px;
  padding-right: 24px;
}

.page-content {
  padding: 24px;
}

.form-two-cols {
  display: flex;
  justify-content: space-between;
  > div {
    width: calc(50% - 12px);
  }
}
.ant-tabs-tab {
  margin: 16px 0px;
  padding: 0 24px;
  flex: 1;
	justify-content: center;
}

.ant-tabs-nav-wrap {
  display: inline-block !important;
}

.display-flex {
  display: flex;
}
.align-center {
  align-items: center;
}

.justify-right {
  justify-content: flex-end;
}

.technical-specification {
  max-width: 50%;
}

.above-table {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 24px;
  @include lg {
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 0;
  }
}

//Dragable list
.dl-item {
  &.activity {
    .category-item {
      opacity: 0.975;
    }
  }
}
.dragged {
  background: white !important;
  border: blue solid green;
}
.activity {
  background: white;
}

.loading-spinner {
  display: block;
  text-align: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.ant-upload-picture-card-wrapper {
  width: fit-content;
}

.ant-upload-select-picture-card {
  margin: 4px;
}

.ant-image-preview-operations{
  background-color: $gray-11;
  padding: 1.125rem 1.5rem;
}

.ant-image-preview-img{
  margin: 5.125rem 0;
  max-height: calc(100vh - 5.125rem);
  background-color: white;
}

.ant-image-preview-operations-operation:last-child, 
.ant-image-preview-operations-operation:nth-last-child(2){
  display: none;
  pointer-events: none;
}