@import '../../../../../styles/colors';

.root{
    width: 100%;
    border:none;

    background-color: $gray-1;
    & li:global(.ant-list-item):nth-child(odd){
        background-color: $gray-3;
    }
}

.link{
    font-weight: 800;
    color: $gray-13;

    &:hover,
    &:focus{
        color: $clr-primary;
    }
}

.icon {
    margin-right: 0.5rem;
}