.root{
    display: grid;
    place-items: center;
    min-height: 100%;
}

.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
}

.buttonGroup{
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;
}

.header{
    font-size: clamp(6em, 20vmin, 20em);
    line-height: 100%;
    margin-bottom: 1rem;
}