@import '../../../../styles/colors';
@import '../../../../styles/_breakpoints';

.root{
    background-color: $gray-1;

    display: flex;
    

    --animation-time: 350ms; 

    transition: transform var(--animation-time) ease var(--animation-time), width 0ms 0ms, opacity 0ms 0ms;
    transform: translateX(0%);
    opacity: 1;

    position: absolute; 

    width: 100%;
    z-index: 1000;
    height: 100%;

    @include lg{
        position:static;
        width: unset; 
        min-width: 18rem;
        height: unset;
    }
}

.fixed{
    background-color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-y: overlay;
    
    position: sticky;
    --height-of-navbar: 5rem;
    top: 0;
    
    width: 100%;
    --documentHeight: 100vh;
    height: calc(var(--documentHeight, 100vh) - var(--height-of-navbar));
    padding: 0rem 0rem;
    
    border-right: solid 1px $gray-5;
    
    @include sm{
        --height-of-navbar: 5.1875rem;
    }

    &:not(:hover){
        overflow-y: hidden;
    }

    @include lg{
        top: var(--height-of-navbar);
    }
}

.rootMobile{
    width: 100%;
    min-height: calc(var(--documentHeight, 100vh) - var(--height-of-navbar));
}

.hidden{
    transition: transform var(--animation-time) ease, width 0ms var(--animation-time), opacity 0ms var(--animation-time);
    transform: translateX(-100%);
    width: 0; 
    opacity: 0;
}

.tabs{
    width: 100%;
    border-bottom: 1px solid $gray-3;
}

.tabButton{
    width: 100%;
    
    justify-content: flex-start;
    gap: 1rem;

    padding: 0.5rem 2.625rem 0.5rem 2.75rem;
    @include lg{
        padding: 0.5rem 1.3125rem 0.5rem 1.5rem;
    }
    font-size: 1.125rem;
    
    &Selected{
        font-weight: 500;
    }

    & span + .tabArrowIcon{
        margin-left: auto;
    }
}

.tabArrowIcon{
    font-size: 0.875rem;
    width: 24px;
    text-align: center;
    display: flex;
    margin: 0;
    & > * {
        transition: transform 250ms ease;
    }

    &Down{
        @extend .tabArrowIcon;
        & > * {
            transform: rotate(0deg);
        }
    }

    &Up{
        @extend .tabArrowIcon;
        & > * {
            transform: rotate(180deg);
        }
    }
}


.buttonLink{
    border-top: 1px solid $gray-3;
    width: 100%;
    text-align: start;
    justify-content: space-between;
    font-size: 1.125rem;

    padding: 0.5rem 3rem;
    @include lg{
        padding: 0.5rem 1.5rem;
    }
}

.error{
    padding: 1rem;
    text-align: center;
    height: 100%;
    display: grid;
    place-content: center;
}

.navLinks{
    @include lg-max{
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        
        height: 4.875rem;

        background-color: $gray-1;
    }
}