@import "../../../../../styles/colors";

.root {
  padding: 2rem 0rem;

  & *:global(.ant-collapse-item){
    border: none;
  }
}

.groupRoot {
  & > div > div >  *:global(.ant-collapse-header){
    background-color: $gray-3;
  }
  background-color: $gray-2;
  margin-bottom: 1.5rem;

  & *:global(.ant-collapse-header):hover,
    & *:global(.ant-collapse-header):focus{
    background-color: $gray-4;
  }
}

.groupName {
  text-transform: uppercase;
  font-size: x-large;
}

.question {
  background-color: $gray-2;
  font-weight: 600;
  text-transform: none;

  // &:hover {
  //   background-color: $gray-4;
  // }
}

.answer {
  font-weight: normal;
  padding-left: 24px;
}