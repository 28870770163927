.wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
}

.select, .input{
    flex: 1 1 45%;
}

.radio{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}