@import '../../../../../styles/colors';

.root{
    width: 100%;
    border:none;

    background-color: $gray-1;

    & li:global(.ant-list-item):nth-child(odd){
        background-color: $gray-3;
    }

    & div.showMore{
        margin: 0.5rem;
        background-color: inherit;
        text-align: center;
        height: 2rem;
        display: flex;
        justify-content: center;
    }
}

.x{
    text-transform: lowercase;
}

.link{
    font-weight: 800;
    color: $gray-13;

    &:hover,
    &:focus{
        color: $clr-primary;
    }
}

.showMoreLink {
    cursor: pointer;
    text-align: center;
    background-color: $clr-primary;
    padding: 0.5rem 1rem;
    font-weight: 700;
    line-height: 1.25rem;
}