@import "../../../../styles/colors";

.root{
    font-size: inherit;
    margin-right: 0.5em;

    & span{
        font-size: inherit;
        color: $gray-11;
    }

    &:hover span{
        color: $clr-primary;
    }
}