@import "./colors";

.button{
    line-height: 1.125em;
    font-size: 0.875rem;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    height: auto;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    text-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        font-size: 1.5em;
    }

    & > span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
    }

    &Primary{
        @extend .button;
        font-weight: 800;
        background-color: var(--clr-primary);
        color: var(--clr-title-85, black);

        &:focus{
            background-color: var(--clr-primary);
            color: var(--clr-title-85, black);
            outline: 2px solid $gray-11;
        }

        &:active,
        &:hover{
            background-color: var(--clr-gray-11, black);
            color: var(--clr-gray-1, white);
            outline: 2px solid $gray-11;
        }

    }

    &PrimaryInverted{
        @extend .button;
        font-weight: 800;
        color: var(--clr-gray-1, white);
        background-color: var(--clr-gray-9, black);

        &:focus{
            color: var(--clr-gray-1, white);
            background-color: var(--clr-gray-9, black);
            outline: 2px solid $gray-11;
        }

        &:active,
        &:hover{
            background-color: var(--clr-gray-11, black);
            color: var(--clr-gray-1, white);
            outline: 2px solid $gray-11;
        }
    }

    &Secondary{
        @extend .button;

        background-color: var(--clr-gray-1, white);
        color: var(--clr-title-85, black);

        outline: 1px solid var(--clr-gray-5, grey);
        outline-offset: -1px;
        
        &:hover,
        &:focus{
            outline: 1px solid var(--clr-gray-5, grey);
            background-color: var(--clr-gray-3, grey);
            color: var(--clr-title-85, black);
        }
    }

    &Text{
        @extend .button;

        background-color: transparent;
        color: var(--clr-gray-13, black);
        
        &:hover,
        &:focus{
            background-color: var(--clr-tranparent-black, lightgray);
            color: var(--clr-gray-13, black);
        }
    }

    &TextInverted{
        @extend .button;
        
        background-color: transparent;
        color: var(--clr-gray-13, black);
        
        &:hover,
        &:focus{
            background-color: transparent;
            color: var(--clr-primary);
        }
    }
}