@import "../../../../styles/breakpoints";

.root {
    min-height: 100%;
    padding: 2rem;
    @include sm{
      padding: 2rem 10rem;
    }
  }
  
  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h1 {
      margin: 0 0.75rem; 

      @include sm{
        margin: 0;
      }
    }
  }

  .title{
    font-size: 2.25;
    font-weight: 400;
    text-transform: uppercase;
}

.noFavorites{
  min-height: calc(100% - 3rem);
  display: grid;
  place-content: center;
  text-align: center;
  padding: 2rem;

  & > * {
    margin: 0 0 2rem;
  }
}