@import './../../../../styles/colors';
@import './../../../../styles/breakpoints';

.root {
    background-color: $gray-8;
    color: $clr-text-primary;
    padding: 2rem 0;
    gap: 1rem;
    flex-direction: column;
    @include md-max {
        flex-direction: column-reverse;
        gap: 3rem;
    }

    @include xl{
        padding: 2rem 0 4rem;
    }
}

.aboutRubrik {
    color: $clr-primary;
    text-align: center;
    font-weight: 800;
    font-size: 2.25rem;
}

.infoTextWrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 5vw;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--contain-width,1800px);
    padding-left: 2rem;
    padding-right: 2rem;
    @include md-max {
        flex-direction: column;
        gap: 3rem;
    }
    .infoText {
        max-width: 35ch;
        text-align: center;
        font-size: 1.125rem;
        @include md-max {
            flex: 0 0 100%;
            max-width: 100%;
        }
        h2 {
            text-align: center;
            font-weight: 800;
            font-size: 1.5rem;
            color: $gray-1;
        }
    }

}
