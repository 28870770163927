@import '../../../../styles/breakpoints';

.wrapper{
    flex-grow: 0 !important;
    height: 100%;
    width: fit-content;
}

.popover{
    opacity: 0;
    --clr-pop: white;
    position: absolute;
    background-color: var(--clr-pop);
    top: calc(100% + 0.5rem);
    z-index: 9999999;
    // left: 0.65rem;
    right: 0;// 0.65rem;
    padding: 0.5rem 0.75rem;
    text-align: center;
    max-width: fit-content;
    // box-shadow: 0 0 0.5rem hsla(0, 0%, 0%, 0.45);
    filter: drop-shadow(0 0 0.25rem hsla(0, 0%, 0%, 0.35));
    border-radius: 0.125rem;

    transition: opacity 250ms ease;
    transition: background-color 150ms ease;

    pointer-events: none;
    
    

    // @include lg{
    //     // left: 1.3rem;
    //     right: 1.3rem;
    // }

    

    &::after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(-0.4rem) translateY(-0.5rem);

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0.75rem 0.5rem 0.75rem;
        border-color: transparent transparent var(--clr-pop) transparent;
    }
}

.button:hover + .popover, 
.show{
   opacity: 1;
}

.show{
    --clr-pop: #f5f5f5;
}