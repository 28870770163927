@import "../../../../styles/breakpoints";

.root {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;

  width: calc(100% - 2rem);

  @include sm{
      width: min(100% - 4rem, 80rem);
  }
  padding-bottom: 6rem;

  overflow-y: visible;
  @include xl {
      max-width: $screen-xl-min;

  }
}

.titleContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    
    h1 {
      margin: 0;
    }
  }

.loadingContainer {
    display: flex;
    justify-content: center;
    padding: 5rem 0rem;
    flex-direction: column;
    align-items: center;
}