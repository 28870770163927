

.loginFormButton {
    width: 100%;
}
.pageBodyCenter {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .cardContainer {
        border: solid black 1px;
        padding: 4rem;
        text-align: center;
    }
}