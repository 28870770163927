@import "../../../../styles/colors";

$list-item-height: 84px;

.root {
  margin-top: 1rem;
}

.addAttributeForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  min-height: $list-item-height;
  border: 1px solid $gray-200;
  background-color: white;
  margin: 1rem 2rem;
  > div {
    margin-bottom: 0;
  }
}

.inputs {
  display: flex;
  >div {
    margin-right: 3rem;
  }
}