.header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.title{
    margin: 0;
    line-height: 1em;
}