@import "../../styles/colors";


.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  cursor: pointer;
}

.actions {
  display:flex;
  align-items: center;
  justify-content: flex-end;
  
  > button {
    margin-left: 2rem;
    margin-bottom: 0;
  }

  > div {
    margin: 0;
  }
}

.editDocumentForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;

  > div {
    margin-bottom: 0;
  }
}

.inputs {
  padding-right: 2rem;

  > div {
    margin: 0;
  }
}
