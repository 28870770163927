@import "../../../../styles/colors";

.root{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4rem;
}

.left{
    flex: 1 1 30rem;
}

.right{
    flex: 1 1 20rem; 
}

.title{
    font-size: 2.25;
    font-weight: 400;
    text-transform: uppercase;
}

