@import './../../styles/colors';

.root {
  padding: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-100;
  h2,
  span {
    margin: 0;
  }
  .product {
    font-size: 16px;
    padding-left: 32px;
    line-height: normal;
  }
}
