@import './../../../../styles/breakpoints';

.root {
    margin-left: auto;
    margin-right: auto;

    width: calc(100% - 2rem);

    @include sm{
        width: min(100% - 4rem, 80rem);
    }
    padding-bottom: 6rem;

    overflow-y: visible;
    @include xl {
        max-width: $screen-xl-min;
  
    }
}
