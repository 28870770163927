@import "../../../../styles/colors";

.root{
    --contain-width: 1920px; // This sets the max-width for a lot of elements on the home page. 
    background-color: $gray-8;
    height: 100%;
}



.cardContainer{
    display: flex;
    justify-content: center;
    background-color: $gray-9;
    
}

.cardContainerEven{
    background-image: linear-gradient(90deg, $clr-primary 0% 100%, $gray-9 0% 50%);
}

.cardWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: var(--contain-width, 1800px);
}

.cardPadding{
    flex-grow: 1;
    flex-basis: 0;
    background-color: $clr-primary;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.cardPaddingEven{
    background-color: $gray-9;
}

.errorWrapper{
    height: 26.125rem;
    display: grid;
    place-content: center;
}