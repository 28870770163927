.imageGridContainer {
  border: 1px solid #cfcfcf;
  padding: 24px;
}

.filmContainer {
  padding-left: 2rem;
  padding-right: 1rem;
}

.avContainer {
  padding-right: 2rem;
  padding-left: 1rem;
}

.root {
  padding-top: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  & h3 {
    margin: 0;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}

.emptyText{
  text-align: center;
  margin: 0;
  padding: 1rem;
}