@import "../../../../styles/colors";

.root{
    background-color: $clr-primary;
    padding: 2rem 1.5rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
    max-width: min(40rem, 100%);
}

.buttonGroup{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    row-gap: 0.5rem;
}

.contactButton {
    padding:  0.125rem 0.75rem !important;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.125rem;

    > * {
        margin: 0 0.25rem;
    }
    
    &:focus, a:focus,
    &:hover, a:hover {
        color: $gray-1;
        background: $gray-13;
    }
    &:active, a:active {
        color: $clr-pressed-action;
        background: $gray-13;
    }
}
