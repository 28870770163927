@import '../../../../styles/colors';
@import '../../../../styles/breakpoints';


@media (hover: hover){
    // Only devices that can hover
    .noHover{
        display: none;
    }
}

@media (hover: none){
    // Only devices that can't hover
    .hasHover{
        display: none;
    }   
}

.tabs {
    border: 1px solid $gray-3;
    margin-bottom: 2rem;
}

.tab {
    padding-left: 1rem;
    padding-right: 1rem;
}

.emptyCartText{
    margin-top: 2.5rem;
    text-align: center;
    color: $clr-disabled-25;
}

.buttonGroup{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.5em 0;
    gap: 0.5em;
    border-bottom: solid 1px $gray-3;
    width: 100%;
    position: relative;


  
}


.buttonGroupAside{
    padding: 0.65em;
    & > *:first-child{
        @include lg{
            width: 100%;
        }
    }
}

.cartGroupText{
    margin-bottom: 0;
    margin-top: 0.125rem;
    color: $clr-character-secondary-45;
    text-align: center;
    font-style: italic;
    font-size: 0.75rem;
    flex-grow: 1;
}

.shareAreaPadding{
    @include lg-max{
        flex-shrink: 0;
        height: 3.5rem;
        width: 100%;
        background-color: $gray-1;
    }
}

.shareButtonContainer{
    padding: 0.65em;
    width: calc(100% - var(--scrollbar-width, 0));

    outline: 1px solid $gray-5;
    margin-top: auto;

    @include lg-max{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    
        background-color: $gray-1;
    }

    & > * {
        flex-grow: 1;
        width: 100%;
    }
}

.buttonGroupAside, .shareButtonContainer, .cartGroupIsAside{
    @include lg{
        padding-left: 1.3em;
        padding-right: 1.3em;
    }
}

.button{
    gap: .5rem; 
    & > span {
        font-size: 0.875rem;
        margin-top: 0.125rem;
    }
}

.buttonInfo{
    padding: 0.25em 1.5em;
    font-size: 0.875em;
    flex-grow: 1;
    min-width: 2.3125rem;
    height: 100%;
    min-height: 2.3125rem;
}

.buttonGrow{
    flex-grow: 1;
}
.disabledText{
    margin-top: 1.25rem;
    color: $clr-disabled-25;
    text-align: center;
}

.modal{
    & :global(.ant-modal-footer){
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        gap: 1rem;
    }
}

.groupButton{
    @include sm{
        min-width: 16rem;
    }
}

.shareButton{
    margin-left: auto;
    flex-grow: 1;

    @include sm{
        flex-grow: 0;
    }
}

.cartGroupButtonWrapper{
    position: relative;
    display: flex;
    gap: inherit;
    flex-grow: 1;
    @include sm{
        flex-grow: 0;
    }


   
    & > *:first-child{
        flex-grow: 1;
        @include sm{
            flex-grow: 0;
        }

        @include lg{
            flex-grow: 1;
        }
    }
}